import React, { useState } from 'react';
import classNames from 'classnames';
import { Link } from "gatsby"
import logoTextImage from '../../images/FStext.png'

function Header() {
  const [active, setActive] = useState(false);

  return (
    <nav className="navbar is-dark is-transparent" role="navigation" aria-label="main navigation">
         <div className="navbar-brand ">
        <Link className="navbar-item" to="/">
          <div className="image-container">
          <img
            src={logoTextImage}
            alt="logo"
            className="logo"
          />
          </div>
        </Link>

        <button 
          className={classNames('navbar-burger burger is-dark', {
            'is-active': active,
          })}
          aria-label="menu"
          aria-expanded="false"
          data-target="navbarBasicExample"
          onClick={() => setActive(!active)}
          
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </button>
      </div>

      <div
        id="navbarBasicExample"
        className={classNames('navbar-menu', { 'is-active': active })}
      >
        <div className="navbar-start">
  
          <Link to="/about" className="navbar-item ">
            About
          </Link>
          <Link to="/contact" className="navbar-item ">
            Contact
          </Link>
          {/* <Link to="/competition" className="navbar-item ">
            Burger Battle Results
          </Link> */}
          {/* <Link to="/diy-burger" className="navbar-item ">
            Burger Kits
          </Link> */}
          {/* <Link to="/deliveroo" className="navbar-item ">
            Deliveroo
          </Link> */}
          <Link to="/" className="navbar-item ">
            Recipes
          </Link>
     
     
     
      
        </div>
      </div>
    </nav>
  );
}

export default Header;
