

import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image';
import './styles/instagram.css'
import { Link } from "gatsby"

const Footer = () => (
  <StaticQuery
    query={graphql`
        query{
            allContentfulAboutPage {
                edges {
                  node {
                    id
                    logo {
                        fluid(maxWidth: 100, maxHeight:100){
                          src
                        ...GatsbyContentfulFluid
                      }
                    }
                    }
                  }
                }
        }
    `}
    render={data => {
      return (
        <footer className="footer is-dark">
          <div className="columns">
            <div className="column is-one-fifth">
              <div className="image-container">
                <Img fluid={data.allContentfulAboutPage.edges[0].node.logo.fluid} imgStyle={{
                  objectFit: "contain",
                }} />
              </div>
              <ul className="has-text-white" >


                <Link to="/template" className="menu-list">
                  {/* <li  className="has-text-white" >Faq for values</li> */}
                </Link>

              </ul>
              {/* <NewsletterForm /> */}
            </div>
            <div className="column is-three-fifth">
            <strong className="has-text-white">hello@foodslut.co.uk</strong>       

            </div>

            <div className="column is-one-fifth">

              <strong className="has-text-white">"Remember there is no dish that adding bacon does not improve"<br></br><br></br>Privacy Policy
       ©2020 FoodSlut&trade;</strong>

            </div>
          </div>

        </footer>
      );
    }}
  />
)


export default Footer

