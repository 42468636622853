import React from "react"
import Header from './header'
import "../../pages/mystyles.scss"
import '../../pages/index.css'
import './styles/follow-footer.css'
import './styles/instagram.css'

import Footer from './Footer'
export default ({ children }) => (
  <div>
    <Header/>
    {children}
    <Footer />
  </div>
)